
import '../style/master.css'
import 'tailwindcss/tailwind.css'


import { useEffect } from 'react'
// const App = ({ Component, pageProps }) => <Component {...pageProps} />

const App = ({ Component, pageProps }) => {

    useEffect(() => {
        require('bootstrap/dist/js/bootstrap.bundle.min.js')
    }, [])

    return <Component {...pageProps} />
}

export default App

